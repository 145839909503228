import { ref, computed } from 'vue';

export function useExpandableText(
  initialText: string,
  lengthToSlice: number,
  maxLength: number,
  el?: Ref<HTMLElement | undefined>,
) {
  const expanded = ref<boolean>(false);
  const text = ref<string>(initialText);

  const shortText = computed(() => {
    if (text.value && text.value.length > maxLength) {
      return text.value.slice(0, lengthToSlice) + '...';
    } else {
      return null;
    }
  });

  const displayText = computed(() => {
    return expanded.value ? text.value : shortText.value;
  });

  const toggleExpanded = () => {
    if (expanded.value) {
      el?.value?.scrollIntoView();
    }
    expanded.value = !expanded.value;
  };

  return {
    expanded,
    text,
    shortText,
    displayText,
    toggleExpanded,
  };
}
